.backups-container {
  height: 100%;
  width: 100%;
}

/* File icons styling */
.file-icon {
  font-size: 1.4rem !important;
}

.folder-icon {
  color: #FFC107 !important;
}

.image-icon {
  color: #673AB7 !important;
}

.pdf-icon {
  color: #F44336 !important;
}

.archive-icon {
  color: #795548 !important;
}

.document-icon {
  color: #2196F3 !important;
}

.spreadsheet-icon {
  color: #4CAF50 !important;
}

.text-icon {
  color: #607D8B !important;
}

.code-icon {
  color: #009688 !important;
}

.default-icon {
  color: #9E9E9E !important;
}

/* File type chips styling */
.file-type-chip {
  font-weight: 500 !important;
  min-width: 80px;
  justify-content: center;
}

.folder-chip {
  background-color: rgba(255, 193, 7, 0.15) !important;
  color: #D68400 !important;
  border: 1px solid rgba(255, 193, 7, 0.3) !important;
}

.html-chip, .css-chip, .js-chip {
  background-color: rgba(0, 150, 136, 0.1) !important;
  color: #00796B !important;
  border: 1px solid rgba(0, 150, 136, 0.3) !important;
}

.image-chip {
  background-color: rgba(103, 58, 183, 0.1) !important;
  color: #512DA8 !important;
  border: 1px solid rgba(103, 58, 183, 0.3) !important;
}

.pdf-chip {
  background-color: rgba(244, 67, 54, 0.1) !important;
  color: #D32F2F !important;
  border: 1px solid rgba(244, 67, 54, 0.3) !important;
}

.doc-chip {
  background-color: rgba(33, 150, 243, 0.1) !important;
  color: #1976D2 !important;
  border: 1px solid rgba(33, 150, 243, 0.3) !important;
}

.excel-chip {
  background-color: rgba(76, 175, 80, 0.1) !important;
  color: #388E3C !important;
  border: 1px solid rgba(76, 175, 80, 0.3) !important;
}

.text-chip {
  background-color: rgba(96, 125, 139, 0.1) !important;
  color: #455A64 !important;
  border: 1px solid rgba(96, 125, 139, 0.3) !important;
}

.archive-chip {
  background-color: rgba(121, 85, 72, 0.1) !important;
  color: #5D4037 !important;
  border: 1px solid rgba(121, 85, 72, 0.3) !important;
}

.other-chip {
  background-color: rgba(158, 158, 158, 0.1) !important;
  color: #616161 !important;
  border: 1px solid rgba(158, 158, 158, 0.3) !important;
}

/* Table styling */
.backups-table {
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
}

.backups-table .ant-table-thead > tr > th {
  background-color: #f5f5f5 !important;
  color: #424242;
  font-weight: 600;
  padding: 12px 16px;
  border-bottom: 1px solid rgba(0, 184, 212, 0.1);
}

.backups-table .ant-table-tbody > tr > td {
  padding: 12px 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  transition: background-color 0.2s ease;
}

.backups-table .ant-table-tbody > tr:hover > td {
  background-color: rgba(0, 184, 212, 0.04) !important;
}

.backups-table .ant-table-tbody > tr.ant-table-row-selected > td {
  background-color: rgba(0, 184, 212, 0.08);
}

/* Zebra striping for better readability */
.backups-table .ant-table-tbody > tr:nth-child(even) > td {
  background-color: rgba(0, 0, 0, 0.01);
}

/* Scrollbar styling */
.backups-table .ant-table-body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.backups-table .ant-table-body::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.03);
  border-radius: 4px;
}

.backups-table .ant-table-body::-webkit-scrollbar-thumb {
  background-color: rgba(0, 184, 212, 0.3);
  border-radius: 4px;
}

.backups-table .ant-table-body::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 184, 212, 0.5);
}

/* File and directory names */
.file-name:hover {
  color: #00b8d4;
  text-decoration: underline;
}

.directory-name {
  font-weight: 500;
  color: #FFC107;
}

.directory-name:hover {
  text-decoration: underline;
}

/* Empty state styling */
.ant-empty-normal {
  margin: 32px 0;
}

/* Improved pagination controls */
.backups-table .ant-pagination {
  margin: 16px 0;
}

.backups-table .ant-pagination-item-active {
  border-color: #00b8d4;
}

.backups-table .ant-pagination-item-active a {
  color: #00b8d4;
}

.backups-table .ant-pagination-item:hover {
  border-color: #00b8d4;
}

.backups-table .ant-pagination-item:hover a {
  color: #00b8d4;
}

.backups-table .ant-pagination-next:hover .ant-pagination-item-link,
.backups-table .ant-pagination-prev:hover .ant-pagination-item-link {
  color: #00b8d4;
  border-color: #00b8d4;
}

/* Animation for loading state */
.backups-table .ant-spin-dot-item {
  background-color: #00b8d4;
}

/* Background grid pattern */
.backups-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: 
    linear-gradient(to right, rgba(0, 184, 212, 0.03) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(0, 184, 212, 0.03) 1px, transparent 1px);
  background-size: 20px 20px;
  pointer-events: none;
  z-index: -1;
} 