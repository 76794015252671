/* Add global styles here */
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', 'Helvetica Neue', sans-serif;
  background-color: #f5f5f5;
}

/* Custom scrollbar styling */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background: #738786;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #5c6c6b;
}

/* Set selection color */
::selection {
  background-color: rgba(28, 58, 79, 0.2);
  color: #242B30;
}

/* Container styles */
.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main-content {
  flex: 1;
  padding: 1rem;
}

/* Utility classes */
.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-container {
  padding: 1rem;
}

/* Custom card styling */
.custom-card {
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.custom-card:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
}

/* Login page styling */
.login-form {
  max-width: 400px;
  margin: 0 auto;
  padding: 2rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .main-content {
    padding: 0.5rem;
  }
  
  .page-container {
    padding: 0.5rem;
  }
}

/* Company style - dark blue for main content area */
#root > div > main {
  background-color: #1C3A4F; /* Prussian blue */
  color: white;
  overflow: auto; /* Enable scrolling if content is too large */
}

.App-header {
  background: none;
  min-height: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: white;
  padding: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.content-container {
  padding: 24px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(36, 43, 48, 0.2); /* Gunmetal with transparency */
  color: #242B30; /* Gunmetal for text */
  overflow: auto; /* Enable scrolling inside containers */
}

/* Make sure the content inside the dark main area is readable */
#root > div > main .content-container {
  margin: 20px;
}

#page_controls {
  background-color: #f5f5f5;
  padding: 10px 20px;
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid rgba(115, 135, 134, 0.2); /* Battleship Gray with transparency */
}

.nav-button {
  min-width: 140px !important;
  text-transform: none !important;
  font-weight: 500 !important;
  border-radius: 0 !important;
}

/* Override Ant Design button styles */
#page_controls .MuiButton-containedPrimary {
  background-color: #1C3A4F !important; /* Prussian blue */
  color: white !important;
}

#page_controls .MuiButton-outlinedInherit {
  color: #242B30 !important; /* Gunmetal */
  border-color: rgba(36, 43, 48, 0.2) !important; /* Gunmetal with transparency */
}

#page_controls .MuiButton-outlinedInherit:hover {
  background-color: rgba(28, 58, 79, 0.04) !important; /* Prussian blue with transparency */
  border-color: #1C3A4F !important; /* Prussian blue */
}

/* Footer styling */
.app-footer {
  padding: 16px;
  background-color: #242B30; /* Gunmetal */
  color: #f5f5f5;
  text-align: center;
  border-top: 1px solid rgba(115, 135, 134, 0.3); /* Battleship Gray with transparency */
}

/* Make sure inputs and form elements follow the theme */
input, 
select, 
textarea,
.ant-input {
  border-color: rgba(28, 58, 79, 0.3) !important; /* Prussian blue with transparency */
}

input:focus, 
select:focus, 
textarea:focus,
.ant-input:focus {
  border-color: #1C3A4F !important; /* Prussian blue */
  box-shadow: 0 0 0 2px rgba(28, 58, 79, 0.2) !important; /* Prussian blue with transparency */
}

/* Style for the URL input bar */
.ant-input-search .ant-input {
  border-color: rgba(28, 58, 79, 0.3) !important; /* Prussian blue with transparency */
}

.ant-input-search .ant-input:hover {
  border-color: rgba(28, 58, 79, 0.5) !important; /* Prussian blue with transparency */
}

.ant-input-search .ant-input:focus {
  border-color: #1C3A4F !important; /* Prussian blue */
  box-shadow: 0 0 0 2px rgba(28, 58, 79, 0.2) !important; /* Prussian blue with transparency */
}

/* Action buttons styling */
.ant-btn-primary {
  background-color: #1C3A4F !important; /* Prussian blue */
  border-color: #1C3A4F !important; /* Prussian blue */
}

.ant-btn-primary:hover {
  background-color: #112330 !important; /* Darker Prussian blue */
  border-color: #112330 !important; /* Darker Prussian blue */
}

.ant-btn-default:hover {
  color: #1C3A4F !important; /* Prussian blue */
  border-color: #1C3A4F !important; /* Prussian blue */
}

/* Style links and headings in the dark main area */
#root > div > main h1, 
#root > div > main h2, 
#root > div > main h3 {
  color: #f5f5f5;
}

#root > div > main a {
  color: #8f9f9e; /* Light Battleship Gray for links */
  text-decoration: none;
}

#root > div > main a:hover {
  color: #abb7b7; /* Lighter Battleship Gray for hover */
  text-decoration: underline;
}

/* Update the page controls to match dark theme */
#root > div > main #page_controls {
  background-color: rgba(36, 43, 48, 0.9); /* Gunmetal with transparency */
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
}

/* Better fix for modals in general */
.ant-modal {
  top: 5% !important;
  margin-top: 0 !important;
  position: relative !important;
}

.ant-modal-content {
  position: relative !important;
  margin: 0 auto !important;
  padding: 0 !important;
  max-height: 90vh !important;
}

.ant-modal-body {
  max-height: calc(85vh - 110px) !important;
  overflow-y: auto !important;
  padding: 24px !important;
}

/* Directory browser modal specific fixes */
.directory-browser-modal {
  position: fixed !important;
  top: 5% !important;
  margin: 0 auto !important;
  max-width: 95vw !important;
  z-index: 9999 !important; /* Ensure it's above everything */
}

.directory-browser-modal .ant-modal-content {
  max-height: 90vh !important;
  margin: 0 auto !important;
  width: 100% !important;
}

.directory-browser-modal .ant-modal-body {
  max-height: 70vh !important;
  overflow-y: auto !important;
  padding: 24px !important;
}

.directory-browser-modal .ant-table-wrapper {
  max-height: 50vh !important;
  overflow-y: auto !important;
}

/* Make sure the table is properly sized inside the modal */
.directory-browser-modal .ant-table {
  text-align: left !important;
  width: 100% !important;
}

/* Fix any container sizing issues */
.directory-browser-modal .ant-modal-body > div {
  height: auto !important;
  max-height: none !important;
  overflow: visible !important;
}

/* Override Ant Design's default modal sizing */
.ant-modal-wrap.directory-browser-modal {
  display: flex !important;
  align-items: flex-start !important;
  justify-content: center !important;
  padding-top: 5vh !important;
}

/* Force the modal to be full-width but with max-width */
.ant-modal.directory-browser-modal {
  max-width: 95vw !important;
  width: 800px !important;
  margin: 0 auto !important;
  top: 0 !important;
}

/* Ensure content doesn't get cut off at bottom */
.directory-browser-modal .ant-modal-footer {
  border-top: 1px solid #f0f0f0 !important;
  padding: 10px 16px !important;
  text-align: right !important;
  background: #fff !important;
}

/* Make modal background visible */
.ant-modal-mask {
  position: fixed !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  z-index: 9998 !important; /* Just below the modal but above everything else */
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.45) !important;
}

/* Monaco editor specific fixes */
.monaco-diff-modal .ant-modal-content {
  overflow: hidden !important;
  margin-top: 5% !important; /* Add 5% margin to the top to prevent content from being cut off */
}

.monaco-diff-modal .ant-modal-body {
  padding-top: 24px !important;
}

/* Fix for the editor being cut off at the top */
.monaco-diff-editor {
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  margin-top: 5px !important;
}

/* Ensure the editor container has enough space */
.monaco-diff-editor .monaco-editor {
  padding-top: 10px !important;
}

/* Add extra space for the top of the diff view */
.monaco-diff-editor .monaco-editor .overflow-guard {
  padding-top: 5px !important;
}

/* Ensure line numbers are visible */
.monaco-diff-editor .margin-view-overlays {
  margin-top: 5px !important;
}

/* Fix specific layout issues with Monaco editor components */
.monaco-editor .editor-scrollable {
  margin-top: 0 !important;
}

.monaco-diff-modal .monaco-editor * {
  box-sizing: border-box !important;
}

/* CHART CENTERING - AGGRESSIVE APPROACH */
/* First, clear all previous chart-related margin and position settings */
[data-highcharts-chart],
.highcharts-container,
canvas[data-highcharts-chart],
svg[data-highcharts-chart],
.highcharts-root,
div:has(> [data-highcharts-chart]),
div:has(> .highcharts-container) {
  margin: 0 !important;
  position: static !important;
  left: auto !important;
  right: auto !important;
  transform: none !important;
}

/* Set explicit centering for all chart-related elements */
.highcharts-container,
.highcharts-root,
svg.highcharts-root,
[data-highcharts-chart],
canvas[data-highcharts-chart] {
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
  width: 100% !important;
  max-width: 900px !important; /* Limit max width */
}

/* Force parent container to be full width */
div.content-container > div {
  width: 100% !important;
  padding: 0 !important;
  display: block !important;
}

/* Target parent div of daily productivity chart specifically */
.content-container h3 + div,
.content-container div:has(> canvas) {
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  padding: 0 !important;
}

/* Extremely aggressive targeting for highcharts elements */
div:has(> canvas),
div:has(> svg),
div:has(> [data-highcharts-chart]) {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 100% !important;
  padding: 0 !important;
  margin: 0 auto !important;
}

/* Target the container with !important to override inline styles */
.highcharts-container,
[id^="highcharts-"] {
  left: 0 !important;
  right: 0 !important;
  margin: 0 auto !important;
  width: 100% !important;
  max-width: 900px !important;
  position: relative !important;
}

/* Specific targeting for daily productivity container */
div:nth-of-type(2) > div:has(> canvas) {
  justify-content: center !important;
  width: 100% !important;
  margin: 0 auto !important;
}

/* Direct targeting of chart elements to center them */
.highcharts-container {
  left: 0 !important;
  right: 0 !important;
  margin-left: auto !important;
  margin-right: auto !important;
  position: relative !important;
  width: 100% !important;
}

/* More direct targeting for charts */
.daily-productivity canvas,
.user-productivity canvas,
div:has(> h3:contains("Daily Productivity")) + div canvas,
div:has(> h3:contains("User Productivity")) + div canvas {
  margin: 0 auto !important;
  display: block !important;
  position: relative !important;
  left: 0 !important;
  right: 0 !important;
}

/* Target all chart wrapper divs */
div[style*="position: relative"] {
  display: flex !important;
  justify-content: center !important;
  margin: 0 auto !important;
  left: 0 !important;
  right: 0 !important;
  width: 100% !important;
}

/* Daily Productivity section styling */
div:has(h4:contains("Daily Productivity")),
div:has(h3:contains("Daily Productivity")),
div:has(> .daily-productivity-chart),
div:has(> canvas[data-highcharts-chart]) {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  width: 100% !important;
  text-align: center !important;
  padding: 0 !important;
  margin: 0 !important;
}

/* User Productivity section styling */
div:has(h4:contains("User Productivity")),
div:has(h3:contains("User Productivity")),
div:has(> .user-productivity-chart) {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  width: 100% !important;
  text-align: center !important;
  padding: 0 !important;
  margin: 0 !important;
}

/* More specific chart selectors */
.ant-card-body div:has(> canvas),
.ant-card-body div:has(> svg),
.content-container div:has(> canvas),
.content-container div:has(> svg),
.daily-productivity-chart,
.user-productivity-chart {
  margin-left: auto !important;
  margin-right: auto !important;
  display: block !important;
  width: 800px !important;
  max-width: 100% !important;
}

/* Target the highcharts container directly */
.highcharts-container {
  margin: 0 auto !important;
}

/* Target the chart in the logs page specifically */
div:has(> h2:contains("File Activity Logs")) canvas,
div:has(> h2:contains("File Activity Logs")) svg,
div:has(> #file_activity_logs) canvas,
div:has(> #file_activity_logs) svg,
div:has(> h3:contains("Daily Productivity")) ~ div canvas,
div:has(> h3:contains("Daily Productivity")) ~ div svg {
  margin: 0 auto !important;
  display: block !important;
}

/* Target specific chart components to force centering */
.highcharts-container[style],
.highcharts-container[style] svg {
  left: 0 !important;
  right: 0 !important;
  margin: 0 auto !important;
}

/* Use absolute positioning to center the chart */
.highcharts-container[style] {
  position: relative !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  width: 800px !important;
  max-width: 100% !important;
}

/* Specific styling for Daily Productivity chart */
h3:contains("Daily Productivity") ~ div {
  margin: 0 auto !important;
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
}

/* Direct canvas targeting */
div canvas[data-highcharts-chart] {
  display: block !important;
  margin: 0 auto !important;
  width: 800px !important;
  max-width: 100% !important;
}

/* Center all SVG charts */
svg[width][height] {
  display: block !important;
  margin: 0 auto !important;
}

/* Center the productivity section */
.user-productivity {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  width: 100% !important;
}

.daily-productivity {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  width: 100% !important;
}

/* Target table sections to ensure they're full width but centered */
.ant-table-wrapper {
  width: 100% !important;
}

/* Center the data visualization areas */
[id^="highcharts-"] {
  margin: 0 auto !important;
}

/* Target the specific chart seen in the screenshot */
div:has(> h3:contains("Daily Productivity")) + div, 
div:has(> h2:contains("Daily Productivity")) + div,
div:has(> h4:contains("Daily Productivity")) + div {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 100% !important;
  padding: 0 !important; /* Remove any padding */
  margin: 0 !important; /* Remove any margin */
}

/* Brute force approach for chart centering */
canvas[data-highcharts-chart] {
  display: block !important;
  margin: 0 auto !important;
  position: relative !important;
  left: -10% !important; /* Shift the chart left to compensate for right alignment */
}

/* Chart container sizing fix */
[data-highcharts-chart],
div:has(> canvas[data-highcharts-chart]) {
  width: 100% !important;
  max-width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  position: relative !important;
  display: flex !important;
  justify-content: center !important;
}

/* Target the Highcharts container specifically */
.highcharts-container {
  margin: 0 auto !important;
  position: relative !important;
  left: 0 !important; /* Reset any positioning */
  display: block !important;
  width: 100% !important; /* Use full width */
}

/* Fix the root container for Highcharts */
.highcharts-root {
  margin: 0 auto !important;
  width: 100% !important;
  position: relative !important;
  left: 0 !important;
}

/* Fix alignment issues for Highcharts */
div[style*="position: relative"] > canvas[data-highcharts-chart] {
  margin: 0 auto !important;
  display: block !important;
  left: 0 !important; /* Reset left position */
  position: relative !important;
  transform: translateX(-50px) !important; /* Shift left by 50px to center */
}

/* Shift all charts slightly left to compensate for right-bias */
.highcharts-container,
[id^="highcharts-"],
.highcharts-root,
canvas[data-highcharts-chart] {
  transform: translateX(-50px) !important; /* Apply a consistent shift left */
}

/* Daily productivity chart specific fix */
[id*="dailyProductivity"],
[id*="daily-productivity"],
canvas[id*="dailyProductivity"],
canvas[id*="daily-productivity"] {
  margin: 0 auto !important;
  position: relative !important;
  left: -50px !important; /* Shift the daily productivity chart left */
}

/* Remove any right-margin or padding that might be causing offset */
div:has(> canvas[data-highcharts-chart]) {
  margin-right: 0 !important;
  padding-right: 0 !important;
  box-sizing: border-box !important;
}

/* Target the specific chart container structure */
body .content-container div:has(> canvas) {
  padding: 0 !important;
  margin: 0 auto !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 100% !important;
}

/* Code diff specific styles */
.diff-container {
  overflow-x: auto !important;
  max-width: 100% !important;
  font-family: monospace;
  white-space: pre;
}

.diff-view {
  display: flex;
  width: 100%;
  overflow: auto;
}

.diff-gutter {
  background-color: #f0f0f0;
  color: #666;
  padding: 0 5px;
  text-align: right;
  user-select: none;
}

.diff-code {
  padding: 0 5px;
  white-space: pre;
}

.diff-line-add {
  background-color: rgba(0, 255, 0, 0.1);
}

.diff-line-delete {
  background-color: rgba(255, 0, 0, 0.1);
}

/* -----------------------------------------------------
   SPECIAL FIX FOR DAILY PRODUCTIVITY CHART ALIGNMENT
   ----------------------------------------------------- */

/* Remove any transforms or positioning set earlier */
[data-highcharts-chart],
.highcharts-container,
canvas[data-highcharts-chart],
svg[data-highcharts-chart],
.highcharts-root {
  transform: none !important;
  left: auto !important;
}

/* This is a direct fix for the chart alignment issue */
body .content-container div.highcharts-container {
  position: relative !important;
  margin: 0 auto !important;
  left: 0 !important;
  right: 0 !important;
  width: 800px !important;
  max-width: 100% !important;
}

/* Add inline css overrides */
body .highcharts-container[style*="left"],
body .highcharts-container[style*="position"] {
  margin: 0 auto !important;
  left: 0 !important;
  right: 0 !important;
  position: relative !important;
}

/* Add this class to specifically target any highcharts root */
.highcharts-root {
  margin: 0 auto !important;
  display: block !important;
}

/* This targets the specific chart in the screenshot */
body .content-container h3 + div .highcharts-container {
  margin: 0 auto !important;
}

/* Brute force approach to override any inline styles */
body .content-container canvas[data-highcharts-chart] {
  display: block !important;
  margin: 0 auto !important;
  position: static !important;
}

/* Target the container of the charts directly */
body .content-container div[style*="position: relative"],
body .content-container div[style*="height:"] {
  margin: 0 auto !important;
  left: 0 !important;
  right: 0 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  max-width: 100% !important;
  width: 100% !important;
}

/* Target the chart parent container */
body .content-container div[style] {
  text-align: center !important;
}

/* Extremely targeted selector for the productivity chart wrapper */
body .content-container div:has(> canvas):not([class]) {
  display: flex !important;
  justify-content: center !important;
  width: 100% !important;
}

/* Direct fix for the daily productivity chart */
body .content-container h3:contains("Daily Productivity") ~ div:nth-of-type(1) {
  display: flex !important;
  justify-content: center !important;
  width: 100% !important;
}

/* Target the specific daily productivity chart with minimal styling */
.ant-card-body .daily-productivity-chart-container {
  width: 100% !important;
  margin: 0 auto !important;
  position: relative !important;
  left: -80px !important; /* Offset to center */
}

/* Target the chart wrapper */
.daily-productivity-chart-container .recharts-wrapper {
  margin: 0 auto !important;
}

/* Secure Upload Component Styles */
.secure-upload-container {
  max-width: 800px;
  margin: 5% auto 0; /* Add top margin of 5% */
  padding: 24px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  color: #000000; /* Make text black */
}

.secure-upload-container h6,
.secure-upload-container p,
.secure-upload-container span,
.secure-upload-container strong,
.secure-upload-container div {
  color: #000000; /* Ensure all text elements are black */
}

.secure-upload-container .format-text {
  color: #000000 !important; /* Force black text */
}

.secure-upload-container .ant-input {
  border-color: rgba(0, 184, 212, 0.3);
}

.secure-upload-container .ant-input:hover {
  border-color: rgba(0, 184, 212, 0.5);
}

.secure-upload-container .ant-input:focus {
  border-color: #00b8d4;
  box-shadow: 0 0 0 2px rgba(0, 184, 212, 0.2);
}

.secure-upload-container .ant-btn {
  border-color: rgba(0, 184, 212, 0.5);
  color: #00b8d4;
}

.secure-upload-container .ant-btn:hover {
  border-color: #00b8d4;
  color: #00b8d4;
  background-color: rgba(0, 184, 212, 0.04);
}

.secure-upload-container .ant-upload {
  width: 100%;
}

.secure-upload-container .ant-upload-select {
  display: block;
  width: 100%;
}

.secure-upload-container .ant-upload-drag {
  border: 2px dashed rgba(0, 184, 212, 0.3);
  background-color: rgba(0, 184, 212, 0.02);
  transition: all 0.3s ease;
}

.secure-upload-container .ant-upload-drag:hover {
  border-color: #00b8d4;
  background-color: rgba(0, 184, 212, 0.04);
}

.secure-upload-container .ant-upload-text {
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  margin: 8px 0;
}

.secure-upload-container .ant-upload-hint {
  color: rgba(0, 0, 0, 0.45);
}

/* Format display styles */
.format-display {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  background-color: rgba(0, 184, 212, 0.05);
  border-radius: 4px;
  margin-bottom: 16px;
}

.format-display .format-icon {
  color: rgba(0, 184, 212, 0.7);
  margin-right: 8px;
}

.format-display .format-text {
  color: rgba(0, 0, 0, 0.65);
  font-family: monospace;
}

/* Upload location display */
.upload-location {
  padding: 12px;
  background-color: rgba(0, 184, 212, 0.08);
  border-radius: 4px;
  border: 1px solid rgba(0, 184, 212, 0.2);
  margin-bottom: 16px;
}

.upload-location-title {
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.7);
  margin-bottom: 8px;
}

.upload-location-path {
  font-family: monospace;
  background-color: #e0f7fa;
  border: 1px solid #00b8d4;
  padding: 4px 12px;
  border-radius: 16px;
  display: inline-block;
}

/* Upload progress styles */
.upload-progress {
  margin-bottom: 16px;
  padding: 12px;
  background-color: rgba(0, 184, 212, 0.08);
  border-radius: 4px;
  border: 1px solid rgba(0, 184, 212, 0.2);
}

.upload-progress-header {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.upload-progress-bar {
  width: 100%;
  height: 8px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  overflow: hidden;
}

.upload-progress-bar-fill {
  height: 100%;
  background-color: #00b8d4;
  border-radius: 4px;
  transition: width 0.3s ease;
}

/* Success message styles */
.upload-success {
  margin-top: 16px;
  padding: 16px;
  background-color: #e8f5e9;
  border: 1px solid #81c784;
  border-radius: 4px;
  text-align: center;
}

.upload-success-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  color: #2e7d32;
}

.upload-success-url {
  margin-top: 8px;
  word-break: break-all;
  font-family: monospace;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 8px;
  border-radius: 4px;
}

/* URL input field specific styles */
.url-input-container {
  display: flex;
  align-items: stretch;
  margin-bottom: 16px;
}

.url-input-field {
  flex: 1;
  margin-right: 8px;
}

.url-input-field .ant-input {
  border-color: rgba(0, 184, 212, 0.3);
}

.url-input-field .ant-input:hover {
  border-color: rgba(0, 184, 212, 0.5);
}

.url-input-field .ant-input:focus {
  border-color: #00b8d4;
  box-shadow: 0 0 0 2px rgba(0, 184, 212, 0.2);
}

/* Browse button styles */
.browse-button {
  min-width: 120px;
  border-color: rgba(0, 184, 212, 0.5);
  color: #00b8d4;
}

.browse-button:hover {
  border-color: #00b8d4;
  background-color: rgba(0, 184, 212, 0.04);
}

/* File selection area styles */
.file-selection {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.selected-file {
  display: flex;
  align-items: center;
  margin-left: 16px;
  padding: 8px 12px;
  background-color: rgba(0, 184, 212, 0.05);
  border: 1px dashed rgba(0, 184, 212, 0.3);
  border-radius: 4px;
}

.selected-file-name {
  margin: 0 8px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Upload button styles */
.upload-button {
  width: 100%;
  margin-top: 16px;
  background: linear-gradient(135deg, #00b8d4 0%, #0088a3 100%);
  border: none;
  color: white;
  font-weight: 500;
  height: 40px;
  transition: all 0.3s ease;
}

.upload-button:hover {
  background: linear-gradient(135deg, #18ffff 0%, #00b8d4 100%);
  transform: translateY(-1px);
  box-shadow: 0 2px 6px rgba(0, 184, 212, 0.2);
}

.upload-button:disabled {
  background: #f5f5f5;
  color: rgba(0, 0, 0, 0.25);
  border: 1px solid #d9d9d9;
  transform: none;
  box-shadow: none;
}

/* Circuit pattern background */
.circuit-pattern {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.03;
  background-image: 
    linear-gradient(to right, rgba(0, 184, 212, 0.5) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(0, 184, 212, 0.5) 1px, transparent 1px);
  background-size: 20px 20px;
  pointer-events: none;
}

/* Additional fixes for directory browser table alignment */
.directory-browser-modal .ant-table-tbody > tr > td {
  text-align: left !important;
  padding-left: 16px !important;
}

.directory-browser-modal .ant-table-row {
  cursor: pointer !important;
}

.directory-browser-modal .ant-table-tbody > tr > td:first-child {
  text-align: left !important;
}

.directory-browser-modal .ant-table-tbody > tr > td > div {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
  text-align: left !important;
}

.directory-browser-modal .file-item {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
  text-align: left !important;
}

/* Additional modal positioning fixes */
.ant-modal-wrap {
  position: fixed !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  z-index: 9999 !important; /* Match modal z-index */
  overflow: auto !important;
  outline: 0 !important;
}

/* Fix for table scrolling */
.directory-browser-modal .ant-table-body {
  max-height: 280px !important;
  overflow-y: auto !important;
}

/* Fix modal header and title */
.directory-browser-modal .ant-modal-header {
  padding: 16px 24px !important;
  border-bottom: 1px solid #f0f0f0 !important;
  border-radius: 2px 2px 0 0 !important;
}

.directory-browser-modal .ant-modal-title {
  margin: 0 !important;
  color: rgba(0, 0, 0, 0.85) !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  word-wrap: break-word !important;
}

/* Ensure modal content is properly positioned and visible */
.ant-modal-content {
  position: relative !important;
  z-index: 10000 !important; /* Above the modal container */
}

/* Force the modal to be visible regardless of other elements */
.ant-modal-root {
  position: fixed !important;
  z-index: 9999 !important;
}

/* Override any z-index issues with the sidebar */
.ant-layout-sider,
.ant-layout-sider-dark,
.sidebar,
.sidebar-container,
.ant-menu,
nav {
  z-index: 100 !important; /* Much lower than the modal */
}

/* Make sure modal portal renders on top */
#modal-root,
#modal-container,
.modal-container,
.ReactModalPortal,
body > .ant-modal-root,
body > div[role="dialog"],
div[data-testid="modal-container"] {
  z-index: 10000 !important;
  position: relative !important;
}

/* Ensure header and nav are below modals */
header,
.header-container,
.navbar,
.navbar-container,
.top-bar,
.top-nav {
  z-index: 100 !important;
}

/* Specific fix for directory browser modal to show over sidebar */
body .ant-modal.directory-browser-modal {
  position: fixed !important;
  z-index: 9999 !important;
}

/* Fix for search input scrolling issue in directory browser modal */
.directory-browser-modal .ant-input-affix-wrapper {
  width: 100% !important;
  max-width: 100% !important;
  position: static !important;
  overflow: visible !important;
}

.directory-browser-modal .ant-input {
  position: static !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  width: 100% !important;
}

/* Ensure search box container doesn't overflow */
.directory-browser-modal .ant-input-affix-wrapper,
.directory-browser-modal .ant-input-affix-wrapper-focused {
  overflow: hidden !important;
}

/* Ensure input content doesn't cause container to scroll */
.directory-browser-modal .ant-input-affix-wrapper .ant-input {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

/* Additional fix to prevent horizontal scrolling in the modal */
.directory-browser-modal .ant-modal-body {
  overflow-x: hidden !important;
}

.directory-browser-modal .ant-modal-body > div {
  overflow-x: hidden !important;
  width: 100% !important;
}

/* Specific fix for the search input */
.directory-search-input {
  max-width: 100% !important;
  overflow: hidden !important;
}

.directory-search-input .ant-input-suffix {
  right: 10px !important;
  position: absolute !important;
}

/* Fix TinyMCE editor display - removing any styling that might interfere */
/* Removing TinyMCE CSS as it's no longer used */

/* Fix code editor styling */
.code-editor-container {
  position: relative !important;
  width: 100% !important;
}

.code-editor-textarea {
  outline: none !important;
  white-space: pre !important;
  caret-color: black !important;
  font-variant-ligatures: none !important;
}

.code-editor-pre {
  white-space: pre !important;
  word-break: keep-all !important;
  overflow-wrap: normal !important;
  overflow-x: auto !important;
}

/* Make sure Prism.js syntax highlighting works */
code[class*="language-"],
pre[class*="language-"] {
  white-space: pre !important;
  word-break: normal !important;
  word-wrap: normal !important;
}

/* Fix for specific styling conflicts */
.code-editor-container div,
.code-editor-container pre,
.code-editor-container textarea {
  display: block !important;
  text-align: left !important;
  font-family: 'Fira Code', 'Fira Mono', monospace !important;
}

/* Monaco editor interactive fixes */
.monaco-editor {
  pointer-events: auto !important;
}

.monaco-editor .monaco-scrollable-element {
  pointer-events: auto !important;
}

.monaco-editor .cursor {
  pointer-events: auto !important;
}

.monaco-editor-background {
  pointer-events: auto !important;
}

.monaco-editor .view-lines {
  pointer-events: auto !important;
}

.monaco-editor-container {
  z-index: 1 !important;
  pointer-events: auto !important;
}

.monaco-editor .overflow-guard {
  pointer-events: auto !important;
}

/* Ensure editor is fully interactive */
.code-editor-container {
  position: relative !important;
  z-index: 1 !important;
  pointer-events: auto !important;
}

/* Fix any overlays that might be blocking interaction */
.code-editor-container * {
  pointer-events: auto !important;
}

/* Aggressive fix for Monaco editor interactivity */
.monaco-editor-instance {
  pointer-events: auto !important;
  position: relative !important;
  z-index: 10 !important;
}

.monaco-editor .monaco-editor-background {
  pointer-events: auto !important;
}

.monaco-editor .lines-content {
  pointer-events: auto !important;
  z-index: 10 !important;
}

.monaco-editor .view-line {
  pointer-events: auto !important;
}

.monaco-editor .view-lines {
  pointer-events: auto !important;
  z-index: 10 !important;
}

.monaco-editor .view-overlays {
  pointer-events: auto !important;
}

/* Override any possible interference */
body .monaco-editor * {
  pointer-events: auto !important;
}

/* Ensure highest z-index for the editor container */
body .code-editor-container {
  z-index: 1000 !important;
}

/* Ensure the cursor is visible and clickable */
.monaco-editor .cursor {
  pointer-events: none !important;
}

/* Make sure the textarea used for input is accessible */
.monaco-editor textarea {
  pointer-events: auto !important;
  z-index: 10 !important;
}

/* Hide only Monaco editor suggestion widgets */
.monaco-editor .suggest-widget,
.monaco-editor .parameter-hints-widget {
  display: none !important;
  visibility: hidden !important;
  opacity: 0 !important;
  pointer-events: none !important;
}

/* Allow search widget with better styling */
.monaco-editor .find-widget {
  visibility: visible !important;
  opacity: 1 !important;
  pointer-events: auto !important;
  z-index: 100 !important;
  background-color: #f5f5f5 !important;
  border: 1px solid #cacaca !important;
  border-radius: 3px !important;
}

/* Make sure find widget input, buttons and icons are visible */
.monaco-editor .find-widget input,
.monaco-editor .find-widget .button,
.monaco-editor .find-widget .monaco-findInput,
.monaco-editor .find-widget .codicon,
.monaco-editor .find-widget svg {
  visibility: visible !important;
  opacity: 1 !important;
  pointer-events: auto !important;
  display: inherit !important;
}

/* Fix specific codicon elements in the find widget */
.monaco-editor .find-widget .codicon {
  width: 16px !important;
  height: 16px !important;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-family: 'codicon' !important;
  font-size: 16px !important;
}

/* Make sure button icons have proper spacing */
.monaco-editor .find-widget .button {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 4px !important;
  margin: 0 2px !important;
  min-width: 20px !important;
  min-height: 20px !important;
  background-color: transparent !important;
}

/* Ensure the find input is properly displayed */
.monaco-editor .find-widget .monaco-findInput {
  display: flex !important;
  margin-right: 3px !important;
}

/* Ensure the find widget has proper padding */
.monaco-editor .find-widget .find-part {
  padding: 4px !important;
  display: flex !important;
  align-items: center !important;
}

/* Custom scrollbar for Monaco editor */
.monaco-editor .monaco-scrollable-element .scrollbar {
  background-color: transparent !important;
}

/* Make sure Monaco editor takes full space */
.monaco-editor-instance {
  height: 100% !important;
  width: 100% !important;
}

.code-editor-container {
  display: flex !important;
  flex-direction: column !important;
  height: 60vh !important;
  overflow: hidden !important;
}

.code-editor-container > div:last-child {
  flex: 1 !important;
  overflow: hidden !important;
}

/* Ensure minimap is visible */
.monaco-editor .minimap {
  display: block !important;
  visibility: visible !important;
  opacity: 1 !important;
  pointer-events: auto !important;
  z-index: 10 !important;
}

/* Custom scrollbar for Monaco editor */
.monaco-editor .monaco-scrollable-element .scrollbar {
  background-color: transparent !important;
}