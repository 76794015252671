.table-row-hover:hover td {
  background-color: rgba(0, 184, 212, 0.04) !important;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: rgba(0, 184, 212, 0.04) !important;
}

/* Make the resize handle more visible */
.react-resizable-handle {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 8px;
  background-color: transparent;
  cursor: row-resize;
  border-top: 2px dotted rgba(0, 184, 212, 0.3);
  opacity: 0;
  transition: opacity 0.2s ease;
}

.react-resizable:hover .react-resizable-handle {
  opacity: 1;
}

/* Style the scrollbar */
.ant-table-body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.ant-table-body::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.ant-table-body::-webkit-scrollbar-thumb {
  background: rgba(0, 184, 212, 0.5);
  border-radius: 4px;
}

.ant-table-body::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 184, 212, 0.7);
}

/* Add a subtle border to the table */
.ant-table {
  border: 1px solid rgba(0, 184, 212, 0.1);
  border-radius: 4px;
}

/* Cybersecurity theme table styling */
.ant-table-thead > tr > th {
  background-color: rgba(0, 184, 212, 0.05) !important;
  color: #424242 !important;
  font-weight: 500 !important;
  border-bottom: 1px solid rgba(0, 184, 212, 0.1) !important;
  padding-left: 16px !important;
  text-align: left !important;
}

/* Add a subtle grid pattern to the table background */
.ant-table-tbody > tr > td {
  background-image: linear-gradient(rgba(0, 184, 212, 0.03) 1px, transparent 1px),
                    linear-gradient(90deg, rgba(0, 184, 212, 0.03) 1px, transparent 1px);
  background-size: 20px 20px;
  background-position: -1px -1px;
  transition: all 0.2s ease;
  padding-left: 16px !important;
  text-align: left !important;
}

/* Loading indicator */
.ant-spin-dot-item {
  background-color: #00b8d4 !important;
}

/* Fix alignment issues with table cells */
.ant-table-cell {
  text-align: left !important;
  padding-left: 16px !important;
}

/* Ensure content inside cells is left aligned */
.ant-table-cell .MuiBox-root {
  justify-content: flex-start !important;
  text-align: left !important;
}

/* Fix alignment for the Name column specifically */
.ant-table-tbody .ant-table-cell:first-child {
  padding-left: 16px !important;
  text-align: left !important;
}

/* Ensure the table is always left-aligned regardless of content width */
.ant-table-container {
  text-align: left !important;
}

/* Special class for left alignment */
.left-aligned-table {
  text-align: left !important;
}

.left-aligned-table th,
.left-aligned-table td {
  text-align: left !important;
  padding-left: 16px !important;
}

.left-aligned-table .ant-table-cell {
  text-align: left !important;
} 