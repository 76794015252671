.user-management-page {
  padding: 20px;
  position: relative;
}

.page-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.users-content {
  margin-top: 20px;
}

/* User avatar in table */
.user-name-cell {
  display: flex;
  align-items: center;
}

.user-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
  background-color: #f0f2f5;
}

/* Form styling */
.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

/* Password reset link modal */
.password-reset-link {
  margin-top: 10px;
  margin-bottom: 10px;
}

/* Access denied page */
.access-denied {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  max-width: 600px;
  margin: 0 auto;
}

.ant-table-row {
  cursor: default !important;
}

/* User row styles */
.ant-table-row:hover {
  background-color: rgba(28, 58, 79, 0.05);
}

/* Role badges */
.ant-tag-green {
  color: #006d75;
  background: #c2f7fa;
  border-color: #87e8f7;
}

.ant-tag-geekblue {
  color: #1d3c7b;
  background: #d6e4ff;
  border-color: #adc6ff;
}

/* Search input styling */
.ant-input-affix-wrapper {
  width: 250px;
}

/* Make sure loading state looks nice */
.ant-spin-container {
  min-height: 200px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .ant-card-extra {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 16px;
  }
  
  .ant-input-affix-wrapper {
    width: 100%;
    margin-bottom: 8px;
  }
  
  .ant-btn {
    margin-bottom: 8px;
  }
  
  .user-management-page {
    padding: 10px;
  }
}

/* Role selection in forms */
.ant-form-item-control-input-content .ant-select {
  width: 100%;
}

.ant-select-dropdown {
  z-index: 2000 !important;
}

.role-select-dropdown {
  z-index: 2000 !important;
}

.role-select .ant-select-selector {
  border-color: #40a9ff !important;
}

.ant-form-item.role-select {
  margin-bottom: 24px;
}

.role-select .ant-select-selection-item {
  font-weight: bold;
}

.ant-select-item-option-selected {
  font-weight: 600;
  background-color: #e6f7ff !important;
} 