.logs-page {
  padding: 0;
  width: 100%;
  height: 100%;
  position: relative;
}

.logs-content {
  width: 100%;
}

.logs-card {
  width: 100%;
  margin-bottom: 20px;
}

.page-loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

/* Fix for table pagination */
.ant-pagination-options {
  margin-left: 16px;
}

/* Ensure sidebar doesn't get hidden */
.ant-layout-sider {
  z-index: 100;
}

/* Fix for charts */
.chart-container {
  width: 100% !important;
  max-width: 900px !important;
  margin: 0 auto !important;
  position: relative !important;
  display: block !important;
  text-align: center !important;
}

.recharts-wrapper {
  margin: 0 auto !important;
  position: relative !important;
  left: 0 !important;
}

.recharts-surface {
  display: block !important;
  margin: 0 auto !important;
}

/* Fix tabs content overflow */
.ant-tabs-content {
  height: auto !important;
  overflow: visible !important;
}

.ant-tabs-tabpane {
  outline: none !important;
}

/* Table spacing */
.ant-table-wrapper {
  margin-bottom: 20px;
}

/* Button spacing */
.ant-btn {
  margin-right: 8px;
}

/* Ensure tab content is visible */
.ant-card-body {
  overflow: visible !important;
}

.logs-page .ant-table-cell {
  padding: 8px 16px;
}

.logs-page .analytics-summary-card {
  text-align: center;
  background-color: #f9f9f9;
  transition: all 0.3s ease;
  height: 100%;
}

.logs-page .analytics-summary-card:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.logs-page .ant-input-search {
  margin-bottom: 12px;
}

.logs-page .recharts-legend-item {
  padding-right: 20px;
}

.logs-page .recharts-layer.recharts-pie {
  cursor: pointer;
}

.logs-page .ant-alert {
  margin-bottom: 20px;
}

.logs-page .date-selector {
  width: 100%;
}

.logs-page .filter-label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
}

.logs-page .ant-statistic-content {
  font-size: 24px;
}

@media (max-width: 768px) {
  .logs-page .ant-card-body {
    padding: 12px;
  }
  
  .logs-page .ant-table-thead > tr > th,
  .logs-page .ant-table-tbody > tr > td {
    padding: 8px 4px;
    font-size: 12px;
  }
  
  .logs-page .ant-statistic-content {
    font-size: 18px;
    white-space: nowrap;
  }
  
  .logs-page .ant-statistic-title {
    font-size: 12px;
  }
  
  .logs-page .recharts-responsive-container {
    height: 250px !important;
  }
}

/* LogsPage specific chart styles */
.recharts-responsive-container {
  margin: 0 auto !important;
}

/* Make sure charts are vertically centered */
.recharts-responsive-container svg,
.recharts-responsive-container canvas {
  vertical-align: middle !important;
  margin: 0 auto !important;
}

/* Direct targeting of LineChart and BarChart */
.recharts-surface {
  margin: 0 auto !important;
  display: block !important;
}

/* Target data visualization parent containers */
.recharts-responsive-container {
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

/* Target Daily Productivity chart */
.daily-productivity-chart-container .recharts-responsive-container {
  margin: 0 auto !important;
  max-width: 100% !important;
}

/* Fix for card layouts */
.ant-card-body {
  padding: 24px !important;
}

/* Fix for ResponsiveContainer width */
.recharts-responsive-container {
  width: 100% !important;
}

/* Target the specific chart in the productivity section */
.ant-tabs-tabpane .recharts-responsive-container {
  margin: 0 auto !important;
  display: block !important;
}

/* Fix chart container alignment */
div[style*="margin"] .recharts-responsive-container {
  margin: 0 auto !important;
}

/* Additional chart styles for better centering */
.daily-productivity-chart-container {
  width: 100% !important;
  text-align: center !important;
}

.daily-productivity-chart {
  margin: 0 auto !important;
  display: block !important;
  max-width: 900px !important;
}

/* Fix alignment for specific productivity chart */
.recharts-composed-chart {
  margin: 0 auto !important;
  width: 100% !important;
  max-width: 100% !important;
}

/* Fix for parent container margin */
.ant-tabs-tabpane > .ant-card-body {
  padding: 0 24px !important;
}

/* Override any charts with style attributes */
.daily-productivity-chart-container [style*="position"],
.daily-productivity-chart-container [style*="margin-left"] {
  margin: 0 auto !important;
  left: 0 !important;
  right: 0 !important;
}

/* Target any charts in tabs */
.ant-tabs-tabpane canvas,
.ant-tabs-tabpane svg {
  display: block !important;
  margin: 0 auto !important;
}

/* Make sure charts are centered */
.ant-card svg,
.ant-card canvas {
  margin: 0 auto !important;
  display: block !important;
}

/* Target specific charts */
.daily-productivity-chart .recharts-wrapper,
[class*="highcharts-container"] {
  margin: 0 auto !important;
  display: block !important;
}

/* Fix table display */
.ant-table-wrapper {
  width: 100% !important;
  max-height: 600px;
  overflow: auto;
}

/* Direct fix for daily productivity chart alignment */
#daily-productivity-chart-container {
  position: relative;
  left: -100px !important;
  width: calc(100% + 200px) !important;
  max-width: 1200px !important;
  overflow: visible !important;
}

/* Chart wrapper fix */
#daily-productivity-chart-container .recharts-wrapper {
  margin: 0 auto !important;
}

/* Make sure the chart is centered regardless of container */
.recharts-responsive-container,
.recharts-wrapper {
  margin: 0 auto !important;
}

/* Ensure that highcharts are properly styled */
[data-highcharts-chart],
.highcharts-container,
.highcharts-root {
  margin: 0 auto !important;
  left: auto !important;
  right: auto !important;
}

/* Tab container for charts */
.chart-tab-container {
  text-align: center;
  width: 100%;
  overflow: visible !important;
  margin: 0 auto;
  display: block;
  position: relative;
}

/* Make chart visible */
.chart-tab-container > div {
  margin: 0 auto !important;
  display: block !important;
}

/* Log detail modal styling */
.log-detail .ant-list-item {
  padding: 12px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.log-detail .ant-list-item:last-child {
  border-bottom: none;
}

.log-detail .ant-list-item-meta-title {
  font-weight: 600;
  color: #1C3A4F;
  margin-bottom: 8px;
}

.log-detail .ant-list-item-meta-description {
  color: rgba(0, 0, 0, 0.85);
}

.log-detail pre {
  background-color: #f5f5f5;
  padding: 8px;
  border-radius: 4px;
  overflow: auto;
  font-size: 12px;
  line-height: 1.5;
}

/* Make table rows clearly clickable */
.ant-table-tbody > tr {
  cursor: pointer;
  transition: background-color 0.3s;
}

.ant-table-tbody > tr:hover {
  background-color: rgba(28, 58, 79, 0.05);
}

/* Add subtle highlight effect on row hover */
.ant-table-tbody > tr:hover td {
  background-color: rgba(28, 58, 79, 0.1) !important;
}

/* Enhanced tab styling */
.logs-page .ant-tabs-tab {
  padding: 12px 20px !important;
  margin-right: 4px !important;
  transition: all 0.3s ease !important;
  border-radius: 8px 8px 0 0 !important;
  cursor: pointer !important;
  user-select: none !important;
}

.logs-page .ant-tabs-tab:hover {
  background-color: rgba(28, 58, 79, 0.1) !important;
}

.logs-page .ant-tabs-tab-active {
  background-color: rgba(28, 58, 79, 0.2) !important;
  font-weight: 600 !important;
}

.logs-page .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #1C3A4F !important;
  font-weight: 600 !important;
}

/* Make the entire tab label clickable */
.logs-page .ant-tabs-tab-btn {
  display: block !important;
  width: 100% !important;
}

.logs-page .ant-tabs-tab span {
  display: flex !important;
  align-items: center !important;
  width: 100% !important;
}

.logs-page .ant-tabs-tab span .anticon {
  margin-right: 8px !important;
}

/* Make the tab container stand out more */
.logs-page .ant-tabs-nav {
  margin-bottom: 16px !important;
  background-color: rgba(245, 245, 245, 0.7) !important;
  padding: 8px 8px 0 8px !important;
  border-radius: 8px 8px 0 0 !important;
}

/* Tab content transition */
.logs-page .ant-tabs-content {
  transition: opacity 0.3s ease-in-out !important;
}

/* Make active tab even more visible */
.logs-page .ant-tabs-tab.ant-tabs-tab-active {
  position: relative !important;
  overflow: visible !important;
  background-color: rgba(28, 58, 79, 0.15) !important;
  border-bottom: 3px solid #1C3A4F !important;
}

/* Add a subtle indicator for active tab */
.logs-page .ant-tabs-tab.ant-tabs-tab-active::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #1C3A4F;
  border-radius: 3px 3px 0 0;
}

/* Make the card stand out less to emphasize the tabs */
.logs-page .ant-card {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08) !important;
}

/* Fix tab vertical alignment */
.logs-page .ant-tabs-tab-btn {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
} 